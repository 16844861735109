import { useState } from "react";
import axios from "axios";
import { getAccessToken } from "../storage/storage";
import toast from "react-hot-toast";

function useUpdateThumbnail() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = getAccessToken();
  const [updateThumbnail, setUpdateThumbnail] = useState({
    data: null,
    loading: false,
    message: null
  });

  const UpdateThumbnail = async (payload) => {
    setUpdateThumbnail((prevState) => ({
      ...prevState,
      loading: true,
      message: null
    }));
    toast
      .promise(
        axios.put(`${BASE_URL}/api/upddate-thumbnail/`, payload, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }),
        {
          loading: "Updating thumbnail ...",
          success: "Your thumbnail has been successfully Updated.",
          error: "Oops! Something went wrong , try again in a while"
        }
      )
      .then((response) => {
        setUpdateThumbnail((prevState) => ({
          ...prevState,
          loading: false,
          data: response.data,
          message: response.data
        }));
      })
      .catch((error) => {
        setUpdateThumbnail((prevState) => ({
          ...prevState,
          loading: false,
          message: null
        }));
      });
  };
  return { UpdateThumbnail, updateThumbnail };
}

export default useUpdateThumbnail;
