import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  getAccessToken,
  setAccessToken,
  setRefreshToken
} from "../storage/storage";
import HomePage from "../pages/HomePage";
import { OauthReceiver } from "react-oauth-flow";
import PageLoader from "../components/shared/PageLoader";
import VideosDetailPage from "../pages/VideosDetailPage";
import PublicVideosDetailPage from "../pages/PublicVideosDetailPage";
import useGetUserInfo from "../hooks/useGetUserInfo";

const Routing = () => {
  const { loading, getCurrentUser } = useGetUserInfo();
  const token = getAccessToken();

  useEffect(() => {
    if (token) {
      getCurrentUser(token);
    }
  }, [token]);

  const handleSuccess = async (accessToken, { response }) => {
    setAccessToken(response.access_token);
    setRefreshToken(response.refresh_token);
    getCurrentUser(response.access_token);
  };
  const handleError = (error) => {};

  return (
    <>
      <Router>
        <Routes>
          {token && (
            <>
              <Route path="/videos/:videoId" element={<VideosDetailPage />} />
            </>
          )}
          <Route path="/" element={<HomePage />} />
          <Route
            path="/all-videos/:videoId"
            element={<PublicVideosDetailPage />}
          />
          <Route path="*" element={<HomePage />} />
        </Routes>
        {!token && (
          <OauthReceiver
            clientId={process.env.REACT_APP_BASE_URL_CLIENTID}
            clientSecret={process.env.REACT_APP_BASE_URL_SECRETID}
            tokenUrl={`${process.env.REACT_APP_BASE_URL_SSO}/token`}
            redirectUri={process.env.REACT_APP_BASE_URL_REDIRECT_URL}
            onAuthSuccess={handleSuccess}
            onAuthError={handleError}
            render={({ processing, state, error }) => (
              <>
                {processing && (
                  <div className="fixed top-0 left-0 w-full h-[100vh] bg-white z-50 flex justify-center items-center">
                    <PageLoader />
                  </div>
                )}
              </>
            )}
          />
        )}
        {loading && (
          <div className="fixed top-0 left-0 w-full h-[100vh] bg-white z-50 flex justify-center items-center">
            <PageLoader />
          </div>
        )}
      </Router>
    </>
  );
};

export default Routing;
