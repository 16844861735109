import React, { useEffect, useRef, useState } from "react";
import AppHead from "../seo/AppHead";
import Layout from "../components/shared/Layout";
import { useParams } from "react-router-dom";
import useGetVideoDetails from "../hooks/useGetVideoDetails";
import { FaRegCirclePause, FaRegCirclePlay } from "react-icons/fa6";
import useGetAllVideos from "../hooks/useGetAllVideos";
import VideoCard from "../components/VideoCard";

function PublicVideosDetailPage() {
  const { videoId } = useParams();
  const { GetVideoDetails, videoDetails } = useGetVideoDetails();
  const { GetAllVideos, allVideos } = useGetAllVideos();
  const [filteredVideos, setFilteredVideos] = useState(null);

  useEffect(() => {
    if (allVideos && videoDetails) {
      const videos = allVideos?.data?.filter(
        (item) => item.id !== videoDetails?.data?.id
      );
      setFilteredVideos(videos);
    }
  }, [allVideos, videoDetails]);

  useEffect(() => {
    if (videoId) {
      GetVideoDetails(videoId);
    }
  }, [videoId]);

  // Get Videos
  useEffect(() => {
    GetAllVideos();
  }, []);

  return (
    <>
      <AppHead title="Video - CrewDog" />
      <Layout>
        <div className="bg-white w-full h-auto relative flex flex-col items-center pt-[30px] pb-[50px]">
          <div
            className="absolute top-0 left-0 md:h-[90vh] xs:h-[50vh]"
            style={{
              background: `url("/assets/images/bg.png")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              width: "100%",
            }}
          />
          <div className="xl:w-[80%] md:w-[90%] flex gap-6 md:flex-row xs:flex-col xs:w-full lg:px-0 xs:px-5 z-40">
            <div className="sm:w-[75%] xs:w-full h-auto shadow-lg">
              <VideoPlayer videoDetails={videoDetails} />
            </div>
            <div className="sm:w-[25%] xs:w-full h-auto">
              <div className="grid grid-cols-2 md:grid-cols-1 lg:grid-cols-1 gap-4 auto-rows-max">
                {filteredVideos?.slice(0, 2)?.map((item, index) => (
                  <VideoCard
                    key={index}
                    item={item}
                    index={index}
                    addToFav={null}
                  />
                ))}
              </div>
            </div>
          </div>
          {filteredVideos?.length > 2 && (
            <div className="xl:w-[80%] md:w-[90%] mt-8 xs:w-full lg:px-0 xs:px-5 z-40">
              <h1 className="text-[#1E2444] text-[20px] font-[600] leading-7">
                Recommended
              </h1>
              <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-4 auto-rows-max mt-2">
                {filteredVideos?.slice(2, 10)?.map((item, index) => (
                  <VideoCard
                    key={index}
                    item={item}
                    index={index}
                    addToFav={null}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
}

export default PublicVideosDetailPage;

function VideoPlayer({ videoDetails }) {
  const videoRef = useRef(null);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [isPlaying, setIsPlaying] = useState(false);
  const [clickPlay, setClickPlay] = useState(false);

  useEffect(() => {
    if (clickPlay) {
      const video = videoRef.current;
      if (video) {
        video.load();
        video
          .play()
          .then(() => {
            setIsPlaying(true);
          })
          .catch((error) => {
            console.error("Error attempting to play the video:", error);
          });
      }
    } else {
      const video = videoRef.current;
      video?.pause();
      setIsPlaying(false);
    }
  }, [clickPlay]);

  const togglePlayback = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video?.pause();
      setIsPlaying(false);
    } else {
      video?.play().catch((error) => {
        console.error("Error attempting to play the video:", error);
      });
      setIsPlaying(true);
    }
  };

  const handleVideoEnd = () => {
    setClickPlay(false);
    setIsPlaying(false);
  };
  return (
    <>
      {!clickPlay ? (
        <div className="relative w-full md:h-[500px] xs:h-[300px] rounded-lg group">
          <img
            src={`${BASE_URL}/${videoDetails?.selectedThumbnail}`}
            alt="Thumbnail"
            className="w-full h-full object-cover rounded-lg cursor-pointer"
          />
          <button
            className={`absolute inset-0 flex items-center justify-center bg-black bg-opacity-20 text-white`}
            onClick={() => setClickPlay(true)}
          >
            {isPlaying ? (
              <FaRegCirclePause className="text-[40px] group-hover:scale-125 transition-transform duration-300 ease-in-out" />
            ) : (
              <FaRegCirclePlay className="text-[40px] group-hover:scale-125 transition-transform duration-300 ease-in-out" />
            )}
          </button>
        </div>
      ) : (
        <div className="relative w-full md:h-[500px] xs:h-[300px] group">
          <video
            className={`w-full h-full object-cover rounded-lg `}
            ref={videoRef}
            controls={true}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onEnded={handleVideoEnd}
          >
            <source src={videoDetails?.data?.url} type="video/mp4" />
            {/* Optionally, you can add a fallback message for unsupported browsers */}
            Your browser does not support the video tag.
          </video>
          {/* <button
            className={`absolute inset-0 flex items-center justify-center  text-white`}
            onClick={togglePlayback}
          >
            {isPlaying ? (
              <FaRegCirclePause className="text-[40px] group-hover:scale-125 transition-transform duration-300 ease-in-out" />
            ) : (
              <FaRegCirclePlay className="text-[40px] group-hover:scale-125 transition-transform duration-300 ease-in-out" />
            )}
          </button> */}
        </div>
      )}
    </>
  );
}
