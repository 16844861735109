import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function useGetGenerateVideo() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const Navigate = useNavigate();

  const GetGenerateVideo = async (taskId, userId) => {
    await axios
      .get(`${BASE_URL}/api/create-video/?task_id=${taskId}&user_id=${userId}`)
      .then((response) => {
        if (response.data.id) {
          Cookies.remove("task_id");
          Navigate(`/videos/${response.data.id}`);
        }
        // if (!response.data.status) {
        //   Cookies.remove("task_id");
        //   toast.success("Sorry, I cannot assist you with this prompt");
        // }
      })
      .catch((error) => {});
  };
  return { GetGenerateVideo };
}
export default useGetGenerateVideo;
