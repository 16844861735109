import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/shared/Layout";
import { useParams } from "react-router-dom";
import useGetVideoDetails from "../hooks/useGetVideoDetails";
import { IoMdShareAlt } from "react-icons/io";
import { LiaDownloadSolid } from "react-icons/lia";
import { CardSkelton_3 } from "../components/shared/Skeltons";
import ShareModal from "../components/modals/ShareModal";
import useUpdateThumbnail from "../hooks/useUpdateThumbnail";
import { FaRegCirclePause, FaRegCirclePlay } from "react-icons/fa6";
import { TiTick } from "react-icons/ti";

function VideosDetailPage() {
  const { videoId } = useParams();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { GetVideoDetails, videoDetails, setVideoDetails } =
    useGetVideoDetails();
  const { UpdateThumbnail, updateThumbnail } = useUpdateThumbnail();

  const thumbnailUpdate = async (thumbnail) => {
    await UpdateThumbnail({ id: videoId, thumbnail: thumbnail });
  };

  useEffect(() => {
    if (videoId) {
      GetVideoDetails(videoId);
    }
  }, [videoId]);

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Layout>
        <div className="bg-white w-full h-auto relative flex flex-col items-center pt-[30px] pb-[50px]">
          <div
            className="absolute top-0 left-0"
            style={{
              background: `url("/assets/images/bg.png")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              width: "100%",
              height: "90vh",
            }}
          />
          <div className="xl:w-[80%] md:w-[90%] xs:w-full lg:px-0 xs:px-5 z-40 flex sm:flex-row flex-col items-center justify-center xl:gap-10 md:gap-6 xs:gap-5">
            <div className="sm:w-[70%] xs:w-full h-auto">
              <VideoPlayer videoDetails={videoDetails} />
            </div>
            <div className="sm:w-[30%] xs:w-full h-auto">
              <div className="flex items-center justify-between">
                <p className="text-[#1A1C1E] text-[20px] font-[500] leading-8">
                  Audio
                </p>
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={videoDetails?.data?.is_audio}
                    readOnly
                    className="sr-only peer"
                  />
                  <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none p rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-secondary"></div>
                </label>
              </div>
              <div className="flex items-center gap-2 mt-6">
                <button
                  onClick={() => setIsOpenModal(true)}
                  className="bg-secondary text-[20px] text-white rounded-lg w-[50%] h-[45px] flex justify-center items-center gap-2 border border-secondary outline-none"
                >
                  <IoMdShareAlt />
                  Share
                </button>
                <button
                  onClick={() => handleDownload(videoDetails?.data?.url)}
                  className="bg-transparent text-[20px] text-secondary rounded-lg w-[50%] h-[45px] flex justify-center items-center gap-2 border border-secondary hover:bg-secondary hover:text-white outline-none"
                >
                  <LiaDownloadSolid />
                  Download
                </button>
              </div>
            </div>
          </div>
          {/* <div className="xl:w-[80%] md:w-[90%] xs:w-full lg:px-0 xs:px-5 z-40 mt-8">
            <h1 className="text-[#1E2444] text-[20px] font-[600] leading-7">
              Generated Thumbnails
            </h1>
            <div className="grid sm:grid-cols-4 xs:grid-cols-2 gap-6 mt-4">
              {videoDetails.loading ? (
                <>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <CardSkelton_3 index={index} />
                  ))}
                </>
              ) : (
                <>
                  {JSON?.parse(
                    videoDetails?.data?.thumbnails?.replace(/'/g, '"')
                  )?.map((item, index) => (
                    <div className="relative rounded-lg">
                      <img
                        key={index}
                        src={`${BASE_URL}/${item}`}
                        alt=""
                        onClick={() => {
                          if (item !== videoDetails.selectedThumbnail) {
                            thumbnailUpdate(item);
                            setVideoDetails((prevState) => ({
                              ...prevState,
                              selectedThumbnail: item,
                            }));
                          }
                        }}
                        className={`w-full h-full object-cover rounded-lg ${
                          item === videoDetails.selectedThumbnail
                            ? "cursor-default"
                            : "cursor-pointer hover:scale-105 transition-transform duration-300 ease-in-out"
                        }`}
                      />
                      {item === videoDetails.selectedThumbnail && (
                        <div
                          className={`absolute top-2 right-2 cursor-pointer bg-secondary
                        rounded-full p-[2px]`}
                        >
                          <TiTick className="text-white text-[20px]" />
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          </div> */}
        </div>
      </Layout>
      <ShareModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        videoId={videoId}
      />
    </>
  );
}

export default VideosDetailPage;

function VideoPlayer({ videoDetails }) {
  const videoRef = useRef(null);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [isPlaying, setIsPlaying] = useState(false);
  const [clickPlay, setClickPlay] = useState(false);

  useEffect(() => {
    if (clickPlay) {
      const video = videoRef.current;
      if (video) {
        video.load();
        video
          .play()
          .then(() => {
            setIsPlaying(true);
          })
          .catch((error) => {
            console.error("Error attempting to play the video:", error);
          });
      }
    } else {
      const video = videoRef.current;
      video?.pause();
      setIsPlaying(false);
    }
  }, [clickPlay]);

  const togglePlayback = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video?.pause();
      setIsPlaying(false);
    } else {
      video?.play().catch((error) => {
        console.error("Error attempting to play the video:", error);
      });
      setIsPlaying(true);
    }
  };

  const handleVideoEnd = () => {
    setClickPlay(false);
    setIsPlaying(false);
  };
  return (
    <>
      {!clickPlay ? (
        <div className="relative w-full md:h-[500px] xs:h-[300px] rounded-lg group">
          <img
            src={`${BASE_URL}/${videoDetails?.selectedThumbnail}`}
            alt="Thumbnail"
            className="w-full h-full object-contain rounded-lg cursor-pointer"
          />
          <button
            className={`absolute inset-0 flex items-center justify-center bg-black bg-opacity-20 text-white`}
            onClick={() => setClickPlay(true)}
          >
            {isPlaying ? (
              <FaRegCirclePause className="text-[40px] group-hover:scale-125 transition-transform duration-300 ease-in-out" />
            ) : (
              <FaRegCirclePlay className="text-[40px] group-hover:scale-125 transition-transform duration-300 ease-in-out" />
            )}
          </button>
        </div>
      ) : (
        <div className="relative w-full md:h-[500px] xs:h-[300px] group">
          <video
            className={`w-full h-full object-cover rounded-lg `}
            ref={videoRef}
            controls={true}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onEnded={handleVideoEnd}
          >
            <source src={videoDetails?.data?.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* <button
            className={`absolute inset-0 flex items-center justify-center  text-white`}
            onClick={togglePlayback}
          >
            {isPlaying ? (
              <FaRegCirclePause className="text-[40px] group-hover:scale-125 transition-transform duration-300 ease-in-out" />
            ) : (
              <FaRegCirclePlay className="text-[40px] group-hover:scale-125 transition-transform duration-300 ease-in-out" />
            )}
          </button> */}
        </div>
      )}
    </>
  );
}
