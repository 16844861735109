import React, { Fragment, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa6";
import { IoCheckmarkOutline, IoCopyOutline } from "react-icons/io5";

function ShareModal(props) {
  const cancelButtonRef = useRef(null);
  const [copied, setCopied] = useState(false);
  const url = `http://localhost:3000/all-videos/${props.videoId}`;

  const handleClose = () => {
    props.setIsOpenModal(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
      })
      .catch((err) => {
        console.error("Failed to copy URL:", err);
      });
  };
  return (
    <Transition.Root show={props.isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 xs:p-1 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-xl bg-white shadow-xl transition-all sm:w-[470px] xs:w-full h-auto xs:mx-2">
                <div className="absolute top-[10px] right-[10px] cursor-pointer">
                  <AiOutlineClose onClick={handleClose} />
                </div>
                <div className="bg-[#F9F9FB] w-full h-[60px] flex items-center px-6">
                  <h1 className="font-[500] text-[20px] text-[#1A1C1E] leading-5">
                    Share Your Video
                  </h1>
                </div>
                <div className="bg-white px-8 py-10 flex flex-col gap-6">
                  <div className="flex items-center justify-between">
                    <TwitterShareButton url={url} title={"crewdog"}>
                      <div className="text-[#1DA1F2]">
                        <div className="bg-[#1DA1F21A] w-[60px] h-[60px] rounded-full flex justify-center items-center cursor-pointer">
                          <FaTwitter className="text-[25px]" />
                        </div>
                        <p className="font-[400] text-[12px] leading-4 mt-2">
                          Twitter
                        </p>
                      </div>
                    </TwitterShareButton>
                    <FacebookShareButton url={url} title={"crewdog"}>
                      <div className="text-[#1877F2]">
                        <div className="bg-[#1877F21A] w-[60px] h-[60px] rounded-full flex justify-center items-center cursor-pointer">
                          <FaFacebookF className="text-[25px]" />
                        </div>
                        <p className="font-[400] text-[12px] leading-4 mt-2">
                          Facebook
                        </p>
                      </div>
                    </FacebookShareButton>
                    <LinkedinShareButton url={url} title={"crewdog"}>
                      <div className="text-[#1877F2]">
                        <div className="bg-[#1877F21A] w-[60px] h-[60px] rounded-full flex justify-center items-center cursor-pointer">
                          <FaLinkedinIn className="text-[25px]" />
                        </div>
                        <p className="font-[400] text-[12px] leading-4 mt-2">
                          LinkedIn
                        </p>
                      </div>
                    </LinkedinShareButton>
                    <WhatsappShareButton url={url} title={"crewdog"}>
                      <div className="text-[#25D366]">
                        <div className="bg-[#25D3661A] w-[60px] h-[60px] rounded-full flex justify-center items-center cursor-pointer">
                          <FaWhatsapp className="text-[25px]" />
                        </div>
                        <p className="font-[400] text-[12px] leading-4 mt-2">
                          WhatsApp
                        </p>
                      </div>
                    </WhatsappShareButton>
                  </div>
                  <div className="border border-[#E2E8F0] h-[45px] w-full rounded-lg flex items-center justify-between px-3">
                    <p className="text-[#8D9CA5] font-[400] text-[14px] leading-5">
                      {url}
                    </p>
                    {copied ? (
                      <IoCheckmarkOutline className="text-green-500" />
                    ) : (
                      <IoCopyOutline
                        className="text-[#718096] cursor-pointer"
                        onClick={copyToClipboard}
                      />
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ShareModal;
