/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useContext, useEffect, useState } from "react";
import Layout from "../components/shared/Layout";
import AppHead from "../seo/AppHead";
import VideoCard from "../components/VideoCard";
import useGenerateVideo from "../hooks/useGenerateVideo";
import Cookies from "js-cookie";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CardSkelton_1 } from "../components/shared/Skeltons";
import Dropdown from "../components/shared/Dropdown";
import useGetAllVideos from "../hooks/useGetAllVideos";
import useGetUserVideos from "../hooks/useGetUserVideos";
import GlobalContext from "../context/GlobalContext";
import { getAccessToken } from "../storage/storage";
import { OauthSender } from "react-oauth-flow";
import useGetGenerateVideo from "../hooks/useGetGenerateVideo";
import useGetFavourite from "../hooks/useGetFavourite";
import { ButtonLoader_1 } from "../components/shared/Loaders";

const ValidationSchema = Yup.object().shape({
  query: Yup.string()
    .required("promt is required")
    .max(2000, "You can't enter more than 2000 characters"),
});

function HomwPage() {
  const token = getAccessToken();
  const taskId = Cookies.get("task_id");
  const [intervalId, setIntervalId] = useState(null);
  const [tabState, setTabState] = useState("Explore");
  const { userInfo, updateResponse } = useContext(GlobalContext);
  const { videoResponse, GenerateVideo } = useGenerateVideo();
  const { GetGenerateVideo } = useGetGenerateVideo();
  const { GetUserVideos, userVideos } = useGetUserVideos();
  const { GetAllVideos, allVideos } = useGetAllVideos();
  const { GetAllFav, favouriteVideos } = useGetFavourite();

  const [magicPromt, setMagicPromt] = useState({
    audio_check: true,
    magic_promt: "auto",
    aspect_ratio: "1.1",
    video_length: "30 Seconds",
    thumbnail: true,
  });

  const initialValues = {
    query: "",
  };

  const { errors, touched, values, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema: ValidationSchema,
    onSubmit: async (values, action) => {
      await GenerateVideo(
        {
          query: values.query,
          audio: magicPromt.audio_check,
        },
        action
      );
    },
  });

  const handleTabClick = useCallback((tabIndex) => {
    setTabState(tabIndex);
  }, []);

  // Get All Videos
  useEffect(() => {
    GetAllVideos();
  }, [updateResponse]);

  useEffect(() => {
    if (userInfo) {
      GetUserVideos();
    }
  }, [userInfo, updateResponse]);

  useEffect(() => {
    GetAllFav();
  }, [updateResponse]);

  // recall generate video api
  useEffect(() => {
    const taskId = Cookies.get("task_id");
    if (taskId && userInfo) {
      GetGenerateVideo(taskId, userInfo?.id);
      const id = setInterval(() => {
        const currentTaskId = Cookies.get("task_id");
        if (currentTaskId) {
          GetGenerateVideo(currentTaskId, userInfo?.id);
        } else {
          clearInterval(id);
        }
      }, 30000);
      setIntervalId(id);
      return () => clearInterval(id);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    }
  }, [userInfo, videoResponse?.data]);

  return (
    <>
      <AppHead title="Home - CrewDog" />
      <Layout>
        <div className="bg-white w-full h-auto relative flex flex-col items-center pt-[30px] pb-[100px]">
          <div
            className="absolute top-0 left-0"
            style={{
              background: `url("/assets/images/bg.png")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              width: "100%",
              height: "90vh",
            }}
          />
          {/* Generate Video */}
          <div className="xl:w-[80%] md:w-[90%] xs:w-full lg:px-0 xs:px-5 z-40">
            <div className="w-full">
              <h1 className="text-[#1E2444] text-[20px] font-[600] leading-7">
                Describe your video
              </h1>
              {token ? (
                <>
                  <form
                    onSubmit={handleSubmit}
                    className="flex xs:flex-col sm:flex-row xs:items-center sm:items-start xs:justify-center sm:justify-between gap-3 mt-2 "
                  >
                    <div className="w-full">
                      <div className="w-full relative">
                        <div className="absolute bottom-5 right-5 text-[#787878] text-[14px] font-[400] leading-4">
                          {values.query.length}/2000
                        </div>
                        <textarea
                          type="text"
                          placeholder="Describe your video"
                          className="w-full sm:min-h-[400px] sm:max-h-[400px] xs:min-h-[200px] xs:max-h-[200px] outline-none px-3 pt-3 pb-8 bg-white rounded-md border border-secondary"
                          // style={{
                          //   borderRadius: "6px",
                          //   border: "1px solid transparent",
                          //   borderImage:
                          //     "linear-gradient(89.18deg, #BF7EFF -10.78%, #6952F4 121.28%) 1",
                          // }}
                          name="query"
                          value={values.query}
                          onChange={handleChange}
                        />
                      </div>
                      {errors.query && touched.query && (
                        <p className="text-red-700 text-xs mt-[2px]">
                          {errors.query}
                        </p>
                      )}
                    </div>

                    <div className="sm:w-[400px] xs:w-full h-[400px] px-3 py-3 bg-white border border-[#E2E8F0] rounded-md flex flex-col">
                      <div className="flex items-center justify-between">
                        <p className="text-[#1A1C1E] text-[14px] font-[500] leading-5">
                          Generate with Audio
                        </p>
                        <label className="inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            value={magicPromt.audio_check}
                            checked={magicPromt.audio_check}
                            onChange={(e) =>
                              setMagicPromt((prevState) => ({
                                ...prevState,
                                audio_check: e.target.checked,
                              }))
                            }
                            className="sr-only peer"
                          />
                          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none p rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-secondary"></div>
                        </label>
                      </div>
                      <div className="flex flex-col mt-4">
                        <label className="text-[14px] text-[#1A1C1E] font-[500] leading-5">
                          Magic Prompt
                        </label>
                        <Dropdown
                          options={["auto", "manual"]}
                          selected={magicPromt.magic_promt}
                          onChange={(option) =>
                            setMagicPromt((prevState) => ({
                              ...prevState,
                              magic_promt: option,
                            }))
                          }
                        />
                      </div>
                      <div className="flex flex-col mt-4">
                        <label className="text-[14px] text-[#1A1C1E] font-[500] leading-5">
                          Aspect Ratio
                        </label>
                        <Dropdown
                          options={["1.1", "1.2", "1.4", "1.6"]}
                          selected={magicPromt.aspect_ratio}
                          onChange={(option) =>
                            setMagicPromt((prevState) => ({
                              ...prevState,
                              aspect_ratio: option,
                            }))
                          }
                        />
                      </div>
                      <div className="flex flex-col mt-4">
                        <label className="text-[14px] text-[#1A1C1E] font-[500] leading-5">
                          Video Length
                        </label>
                        <Dropdown
                          options={[
                            "30 Seconds",
                            "1 Minute",
                            "2 Minutes",
                            "3 Minutes",
                          ]}
                          selected={magicPromt.video_length}
                          onChange={(option) =>
                            setMagicPromt((prevState) => ({
                              ...prevState,
                              video_length: option,
                            }))
                          }
                        />
                      </div>
                      <div className="flex items-center justify-between mt-4">
                        <p className="text-[#1A1C1E] text-[14px] font-[500] leading-5">
                          Generate Thumbnail
                        </p>
                        <label className="inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            value={magicPromt.thumbnail}
                            checked={magicPromt.thumbnail}
                            onChange={(e) =>
                              setMagicPromt((prevState) => ({
                                ...prevState,
                                thumbnail: e.target.checked,
                              }))
                            }
                            className="sr-only peer"
                          />
                          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none p rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-secondary"></div>
                        </label>
                      </div>
                      <div className="w-full h-[2px] bg-[#EEEEEE] my-3" />
                      {token ? (
                        <button
                          className="w-full h-[50px] text-white text-[16px] font-[600] leading-6 rounded-md flex items-center justify-center gap-2 cursor-pointer"
                          style={{
                            backgroundImage:
                              "linear-gradient(89.18deg, #BF7EFF -10.78%, #6952F4 121.28%)",
                          }}
                          title={
                            !videoResponse.loading || !taskId
                              ? "Click here to generate video"
                              : ""
                          }
                          disabled={videoResponse.loading || taskId}
                        >
                          {videoResponse.loading || taskId ? (
                            <ButtonLoader_1 />
                          ) : (
                            <img src="/assets/icons/2.png" alt="" />
                          )}
                          {videoResponse.loading || taskId
                            ? "Generating..."
                            : "Generate Video"}
                        </button>
                      ) : (
                        <OauthSender
                          clientId={process.env.REACT_APP_BASE_URL_CLIENTID}
                          authorizeUrl={`${process.env.REACT_APP_BASE_URL_SSO}/authorize`}
                          redirectUri={
                            process.env.REACT_APP_BASE_URL_REDIRECT_URL
                          }
                          render={({ url }) => (
                            <a
                              href={url}
                              className="w-full h-[50px] text-white text-[16px] font-[600] leading-6 rounded-md flex items-center justify-center gap-2 cursor-pointer"
                              style={{
                                backgroundImage:
                                  "linear-gradient(89.18deg, #BF7EFF -10.78%, #6952F4 121.28%)",
                              }}
                            >
                              <img src="/assets/icons/2.png" alt="" />
                              Generate Video
                            </a>
                          )}
                        />
                      )}
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <div className="relative">
                    <form
                      onSubmit={handleSubmit}
                      className="flex xs:flex-col sm:flex-row xs:items-center sm:items-start xs:justify-center sm:justify-between gap-3 mt-2 "
                    >
                      <div className="w-full">
                        <div className="w-full relative">
                          <div className="absolute bottom-5 right-5 text-[#787878] text-[14px] font-[400] leading-4">
                            {values.query.length}/2000
                          </div>
                          <textarea
                            type="text"
                            placeholder="Describe your video"
                            className="w-full sm:min-h-[400px] sm:max-h-[400px] xs:min-h-[200px] xs:max-h-[200px] outline-none px-3 pt-3 pb-8 bg-white rounded-md border border-secondary"
                            // style={{
                            //   borderRadius: "6px",
                            //   border: "1px solid transparent",
                            //   borderImage:
                            //     "linear-gradient(89.18deg, #BF7EFF -10.78%, #6952F4 121.28%) 1",
                            // }}
                            name="query"
                            value={values.query}
                            onChange={handleChange}
                          />
                        </div>
                        {errors.query && touched.query && (
                          <p className="text-red-700 text-xs mt-[2px]">
                            {errors.query}
                          </p>
                        )}
                      </div>

                      <div className="sm:w-[400px] xs:w-full h-[400px] px-3 py-3 bg-white border border-[#E2E8F0] rounded-md flex flex-col">
                        <div className="flex items-center justify-between">
                          <p className="text-[#1A1C1E] text-[14px] font-[500] leading-5">
                            Generate with Audio
                          </p>
                          <label className="inline-flex items-center cursor-pointer">
                            <input
                              type="checkbox"
                              value={magicPromt.audio_check}
                              checked={magicPromt.audio_check}
                              onChange={(e) =>
                                setMagicPromt((prevState) => ({
                                  ...prevState,
                                  audio_check: e.target.checked,
                                }))
                              }
                              className="sr-only peer"
                            />
                            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none p rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-secondary"></div>
                          </label>
                        </div>
                        <div className="flex flex-col mt-4">
                          <label className="text-[14px] text-[#1A1C1E] font-[500] leading-5">
                            Magic Prompt
                          </label>
                          <Dropdown
                            options={["auto", "manual"]}
                            selected={magicPromt.magic_promt}
                            onChange={(option) =>
                              setMagicPromt((prevState) => ({
                                ...prevState,
                                magic_promt: option,
                              }))
                            }
                          />
                        </div>
                        <div className="flex flex-col mt-4">
                          <label className="text-[14px] text-[#1A1C1E] font-[500] leading-5">
                            Aspect Ratio
                          </label>
                          <Dropdown
                            options={["1.1", "1.2", "1.4", "1.6"]}
                            selected={magicPromt.aspect_ratio}
                            onChange={(option) =>
                              setMagicPromt((prevState) => ({
                                ...prevState,
                                aspect_ratio: option,
                              }))
                            }
                          />
                        </div>
                        <div className="flex flex-col mt-4">
                          <label className="text-[14px] text-[#1A1C1E] font-[500] leading-5">
                            Video Length
                          </label>
                          <Dropdown
                            options={[
                              "30 Seconds",
                              "1 Minute",
                              "2 Minutes",
                              "3 Minutes",
                            ]}
                            selected={magicPromt.video_length}
                            onChange={(option) =>
                              setMagicPromt((prevState) => ({
                                ...prevState,
                                video_length: option,
                              }))
                            }
                          />
                        </div>
                        <div className="flex items-center justify-between mt-4">
                          <p className="text-[#1A1C1E] text-[14px] font-[500] leading-5">
                            Generate Thumbnail
                          </p>
                          <label className="inline-flex items-center cursor-pointer">
                            <input
                              type="checkbox"
                              value={magicPromt.thumbnail}
                              checked={magicPromt.thumbnail}
                              onChange={(e) =>
                                setMagicPromt((prevState) => ({
                                  ...prevState,
                                  thumbnail: e.target.checked,
                                }))
                              }
                              className="sr-only peer"
                            />
                            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none p rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-secondary"></div>
                          </label>
                        </div>
                        <div className="w-full h-[2px] bg-[#EEEEEE] my-3" />
                        {token ? (
                          <button
                            className="w-full h-[50px] text-white text-[16px] font-[600] leading-6 rounded-md flex items-center justify-center gap-2 cursor-pointer"
                            style={{
                              backgroundImage:
                                "linear-gradient(89.18deg, #BF7EFF -10.78%, #6952F4 121.28%)",
                            }}
                            title={
                              !videoResponse.loading || !taskId
                                ? "Click here to generate video"
                                : ""
                            }
                            disabled={videoResponse.loading || taskId}
                          >
                            {videoResponse.loading || taskId ? (
                              <ButtonLoader_1 />
                            ) : (
                              <img src="/assets/icons/2.png" alt="" />
                            )}
                            {videoResponse.loading || taskId
                              ? "Generating..."
                              : "Generate Video"}
                          </button>
                        ) : (
                          <OauthSender
                            clientId={process.env.REACT_APP_BASE_URL_CLIENTID}
                            authorizeUrl={`${process.env.REACT_APP_BASE_URL_SSO}/authorize`}
                            redirectUri={
                              process.env.REACT_APP_BASE_URL_REDIRECT_URL
                            }
                            render={({ url }) => (
                              <a
                                href={url}
                                className="w-full h-[50px] text-white text-[16px] font-[600] leading-6 rounded-md flex items-center justify-center gap-2 cursor-pointer"
                                style={{
                                  backgroundImage:
                                    "linear-gradient(89.18deg, #BF7EFF -10.78%, #6952F4 121.28%)",
                                }}
                              >
                                <img src="/assets/icons/2.png" alt="" />
                                Generate Video
                              </a>
                            )}
                          />
                        )}
                      </div>
                    </form>
                    <div className="flex justify-center items-center w-full h-full bg-black bg-opacity-[30%] top-[-4px] rounded-lg absolute ">
                      {/* <OauthSender
                        clientId={process.env.REACT_APP_BASE_URL_CLIENTID}
                        authorizeUrl={`${process.env.REACT_APP_BASE_URL_SSO}/authorize`}
                        redirectUri={
                          process.env.REACT_APP_BASE_URL_REDIRECT_URL
                        }
                        render={({ url }) => (
                          <a
                            href={url}
                            className="w-[120px] h-[50px] text-white text-[16px] font-[600] leading-6 rounded-md flex items-center justify-center gap-2 cursor-pointer"
                            style={{
                              backgroundImage:
                                "linear-gradient(89.18deg, #BF7EFF -10.78%, #6952F4 121.28%)",
                              padding: "0 20px",
                            }}
                          >
                            Sign In
                          </a>
                        )}
                      /> */}

                      <button
                        className="w-[120px] h-[50px] text-white text-[16px] font-[600] leading-6 rounded-md flex items-center justify-center gap-2 cursor-pointer"
                        style={{
                          backgroundImage:
                            "linear-gradient(89.18deg, #BF7EFF -10.78%, #6952F4 121.28%)",
                          padding: "0 20px",
                        }}
                      >
                        Sign In
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* Explore, My Videos and Favorite tabs */}
            <div className="flex flex-col items-center mt-5">
              <div className="flex items-center gap-5">
                {[
                  { id: 1, tab_name: "Explore", loggedIn: true },
                  {
                    id: 2,
                    tab_name: "My Videos",
                    loggedIn: token ? true : false,
                  },
                  {
                    id: 3,
                    tab_name: "Favorite",
                    loggedIn: token ? true : false,
                  },
                ].map((item, index) => (
                  <button
                    key={index}
                    className={`sm:text-[20px] xs:text-[15px] font-[500] leading-5 bg-transparent md:w-[150px] sm:w-[100px] xs:w-[70px] h-[42px]  ${
                      tabState === item.tab_name
                        ? "text-[#1E2444]"
                        : "text-[#718096] "
                    } ${
                      item.loggedIn
                        ? "hover:text-[#1E2444] cursor-pointer"
                        : "text-gray-300 cursor-auto"
                    }`}
                    style={{
                      borderBottom: "2px solid transparent",
                      borderImage:
                        tabState === item.tab_name
                          ? "linear-gradient(89.18deg, #BF7EFF -10.78%, #6952F4 121.28%) 1"
                          : "none",
                    }}
                    onClick={() => {
                      if (token && item.loggedIn) {
                        handleTabClick(item.tab_name);
                      }
                    }}
                  >
                    {item.tab_name}
                  </button>
                ))}
              </div>
              <div className="mt-5 w-full">
                {tabState === "Explore" && (
                  <Explore
                    allVideos={allVideos}
                    favouriteVideos={favouriteVideos}
                  />
                )}
                {tabState === "My Videos" && (
                  <MyVideos
                    userVideos={userVideos}
                    favouriteVideos={favouriteVideos}
                  />
                )}
                {tabState === "Favorite" && (
                  <Favorite favouriteVideos={favouriteVideos} />
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default HomwPage;

const Explore = (props) => {
  const isFavourite = (videoId, favouriteVideos) => {
    if (videoId && favouriteVideos) {
      return favouriteVideos?.some((fav) => fav.video_id === videoId);
    }
  };
  return (
    <>
      {!props.allVideos?.loading && props.allVideos?.message ? (
        <div className="text-gray-500 text-[15px] font-[500] leading-5 flex justify-center items-center mt-10">
          {props.allVideos.message}
        </div>
      ) : (
        <>
          {props.allVideos?.loading ? (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 auto-rows-max">
              {Array.from({ length: 8 }).map((_, index) => (
                <CardSkelton_1 key={index} index={index} />
              ))}
            </div>
          ) : (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 auto-rows-max">
              {props.allVideos?.data?.map((item, index) => (
                <VideoCard
                  key={index}
                  item={item}
                  index={index}
                  addToFav={false}
                  isFav={isFavourite(item.id, props.favouriteVideos?.data)}
                />
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

const MyVideos = (props) => {
  const isFavourite = (videoId, favouriteVideos) => {
    if (videoId && favouriteVideos) {
      return favouriteVideos?.some((fav) => fav.video_id === videoId);
    }
  };
  return (
    <>
      {!props.userVideos.loading && props.userVideos.message ? (
        <div className="text-gray-500 text-[15px] font-[500] leading-5 flex justify-center items-center mt-10">
          {props.userVideos.message}
        </div>
      ) : (
        <>
          {props.userVideos.loading ? (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 auto-rows-max">
              {Array.from({ length: 8 }).map((_, index) => (
                <CardSkelton_1 key={index} index={index} />
              ))}
            </div>
          ) : (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 auto-rows-max">
              {props.userVideos?.data?.map((item, index) => (
                <VideoCard
                  key={index}
                  item={item}
                  index={index}
                  addToFav={false}
                  isFav={isFavourite(item.id, props.favouriteVideos?.data)}
                />
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

const Favorite = (props) => {
  return (
    <>
      {!props.favouriteVideos?.loading && props.favouriteVideos?.message ? (
        <div className="text-gray-500 text-[15px] font-[500] leading-5 flex justify-center items-center mt-10">
          {props.favouriteVideos.message}
        </div>
      ) : (
        <>
          {props.favouriteVideos?.loading ? (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 auto-rows-max">
              {Array.from({ length: 8 }).map((_, index) => (
                <CardSkelton_1 key={index} index={index} />
              ))}
            </div>
          ) : (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 auto-rows-max">
              {props.favouriteVideos?.data?.map((item, index) => (
                <VideoCard
                  key={index}
                  item={item}
                  index={index}
                  addToFav={true}
                />
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};
