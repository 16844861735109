import { useState } from "react";
import axios from "axios";
import { getAccessToken } from "../storage/storage";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function useGenerateVideo() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = getAccessToken();
  const Navigate = useNavigate();
  const [videoResponse, setVideoResponse] = useState({
    data: null,
    loading: false,
    message: null,
  });

  const GenerateVideo = async (payload, action) => {
    setVideoResponse((prevState) => ({
      ...prevState,
      loading: true,
      message: null,
    }));

    await axios
      .post(`${BASE_URL}/api/create-video/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        Cookies.set("task_id", response.data.task_id);
        setVideoResponse((prevState) => ({
          ...prevState,
          loading: false,
          data: response.data,
          message: null,
        }));
        Navigate(`videos/${response.data.data.id}`);
      })
      .catch((error) => {
        setVideoResponse((prevState) => ({
          ...prevState,
          loading: false,
          message: "Oops! Something went wrong , try again in a while",
        }));
      });
  };

  return { GenerateVideo, videoResponse, setVideoResponse };
}

export default useGenerateVideo;
