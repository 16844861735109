import { useContext, useState } from "react";
import axios from "axios";
import { getAccessToken } from "../storage/storage";
import toast from "react-hot-toast";
import GlobalContext from "../context/GlobalContext";

function useAddFavourite() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = getAccessToken();
  const { setUpdateResponse } = useContext(GlobalContext);
  const [addFavourite, setAddFavourite] = useState({
    loading: false,
    message: null,
  });

  const AddToFav = async (payload) => {
    setAddFavourite((prevState) => ({
      ...prevState,
      loading: true,
      message: null,
    }));

    await axios
      .post(`${BASE_URL}/api/favorite/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        toast.success("Video added to favorites");
        setUpdateResponse(true);
        setAddFavourite((prevState) => ({
          ...prevState,
          loading: false,
          message: "Video added to favorites",
        }));
      })
      .catch((error) => {
        console.log({ error });
        toast.error(error?.response?.data?.message);
        setAddFavourite((prevState) => ({
          ...prevState,
          loading: false,
          message: "Oops! Something went wrong , try again in a while",
        }));
      });
  };

  return { AddToFav, addFavourite };
}

export default useAddFavourite;
