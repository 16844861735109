const config = {
  seo: {
    title: "CrewDog",
    description:
      "Welcome to CrewDog, the ultimate destination for turning your written content into captivating videos effortlessly. Whether you're a content creator, marketer, educator, or business owner, our platform empowers you to create professional-quality videos in just a few simple steps.",
    url: "",
    image: "",
    site_name: "CrewDog",
    twitter: {
      handle: "@handle",
      cardType: "summary_large_image",
    },
  },
  baseUrl: "",
  assetsUrl: "",
};

export default config;
