import { useState } from "react";
import axios from "axios";

function useGetVideoDetails() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [videoDetails, setVideoDetails] = useState({
    data: null,
    loading: true,
    message: null,
    selectedThumbnail: null,
  });

  const GetVideoDetails = async (videoId) => {
    await axios
      .get(`${BASE_URL}/api/get-video?id=${videoId}`)
      .then((response) => {
        setVideoDetails((prevState) => ({
          ...prevState,
          loading: false,
          data: response.data,
          selectedThumbnail: response.data.thumbnail_url,
          message: null,
        }));
      })
      .catch((error) => {
        setVideoDetails((prevState) => ({
          ...prevState,
          loading: false,
          selectedThumbnail: null,
          message:
            error?.response?.data?.message || "Oops! Something went wrong",
        }));
      });
  };
  return { GetVideoDetails, videoDetails, setVideoDetails };
}
export default useGetVideoDetails;
