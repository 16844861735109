import React, { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import Cookies from "js-cookie";
import { IoMdNotificationsOutline } from "react-icons/io";
import {
  getAccessToken,
  removeAccessToken,
  removeRefreshToken,
} from "../../storage/storage";
import { OauthSender } from "react-oauth-flow";
import { Link } from "react-router-dom";

function Navbar() {
  const dropdownRef = useRef(null);
  const token = getAccessToken();
  const { userInfo } = useContext(GlobalContext);
  const BASE_URL = process.env.REACT_APP_BASE_URL_SSO;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const logout = () => {
    removeAccessToken();
    removeRefreshToken();
    window.location.href = "/";
    Cookies.remove("task_id");
    Cookies.set("loading", false);
  };

  return (
    <>
      <nav className="w-full h-[65px] sm:px-6 xs:px-3 flex items-center justify-between sticky top-0 z-50 bg-white border-b-[1px] border-[#E2E8F0]">
        <div className="flex items-center">
          <Link to="/">
            <img
              src="/assets/logo/logo.png"
              alt="CrewDog"
              className="w-[100px]"
            />
          </Link>
        </div>
        {token ? (
          <div className="flex items-center gap-6">
            <div className="relative">
              <IoMdNotificationsOutline className="text-[25px] hover:text-secondary cursor-pointer" />
              <div className="absolute top-[-2px] right-0 h-2 w-2 bg-secondary rounded-full notification-blink"></div>
            </div>
            <div className="sm:flex items-center gap-3 px-4 border-l-[1px] border-r-[1px] border-[#cecece] xs:hidden">
              <img src="/assets/icons/1.png" alt="" />
              <p className="text-[#1E2444] text-[14px] font-[600] leading-5 cursor-pointer hover:text-secondary">
                Upgrade Plan
              </p>
            </div>
            {userInfo?.profile_pic ? (
              <div className="relative" ref={dropdownRef}>
                <button title="Profile">
                  <img
                    src={`${BASE_URL}/${userInfo?.profile_pic}`}
                    alt=""
                    className="w-[45px] h-[45px] rounded-full cursor-pointer"
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                  />
                </button>
                {dropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-lg">
                    <Link
                      to="#"
                      className="sm:hidden xs:flex items-center gap-2 px-4 py-2 text-gray-800 hover:bg-gray-100 hover:rounded-t-md"
                    >
                      <img src="/assets/icons/1.png" alt="" />
                      Upgrade Plan
                    </Link>
                    <Link
                      to="#"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                    >
                      Profile
                    </Link>
                    <Link
                      to="#"
                      onClick={logout}
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 hover:rounded-b-md"
                    >
                      Logout
                    </Link>
                  </div>
                )}
              </div>
            ) : (
              <div className="relative" ref={dropdownRef}>
                <svg
                  className="w-[45px] h-[45px] text-gray-200 dark:text-gray-700 cursor-pointer"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                </svg>
                {dropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white border rounded-md shadow-lg">
                    <Link
                      to="#"
                      className="sm:hidden xs:flex items-center gap-2 px-4 py-2 text-gray-800 hover:bg-gray-100 hover:rounded-t-md"
                    >
                      <img src="/assets/icons/1.png" alt="CrewDog" />
                      Upgrade Plan
                    </Link>
                    <Link
                      to="#"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                    >
                      Profile
                    </Link>
                    <Link
                      to="#"
                      onClick={logout}
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100 hover:rounded-b-md"
                    >
                      Logout
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          // <OauthSender
          //   clientId={process.env.REACT_APP_BASE_URL_CLIENTID}
          //   authorizeUrl={`${process.env.REACT_APP_BASE_URL_SSO}/authorize`}
          //   redirectUri={process.env.REACT_APP_BASE_URL_REDIRECT_URL}
          //   render={({ url }) => (
          //     <a
          //       href={url}
          //       className="py-2 px-6 flex justify-center items-center rounded-[4px] text-[20px] font-medium text-white"
          //       style={{
          //         backgroundImage:
          //           "linear-gradient(89.18deg, #BF7EFF -10.78%, #6952F4 121.28%)",
          //       }}
          //     >
          //       Sign In
          //     </a>
          //   )}
          // />

          <button
            className="w-[120px] h-[50px] text-white text-[16px] font-[600] leading-6 rounded-md flex items-center justify-center gap-2 cursor-pointer"
            style={{
              backgroundImage:
                "linear-gradient(89.18deg, #BF7EFF -10.78%, #6952F4 121.28%)",
              padding: "0 20px",
            }}
          >
            Sign In
          </button>
        )}
      </nav>
    </>
  );
}

export default Navbar;
