import { useContext, useState } from "react";
import axios from "axios";
import { getAccessToken } from "../storage/storage";
import GlobalContext from "../context/GlobalContext";

function useGetFavourite() {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { setUpdateResponse } = useContext(GlobalContext);
  const [favouriteVideos, setFavouriteVideos] = useState({
    data: null,
    loading: true,
    message: null,
  });

  const GetAllFav = async () => {
    await axios
      .get(`${BASE_URL}/api/favorite/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUpdateResponse(false);
        setFavouriteVideos((prevState) => ({
          ...prevState,
          loading: false,
          data: [...(response.data || [])].reverse(),
          message: response.data.length === 0 ? "No videos found" : null,
        }));
      })
      .catch((error) => {
        setFavouriteVideos((prevState) => ({
          ...prevState,
          loading: false,
          message: error?.response?.data?.message || "Coming Soon",
        }));
      });
  };
  return { GetAllFav, favouriteVideos };
}
export default useGetFavourite;
