import { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";

export const CardSkelton_1 = ({ index }) => {
  return (
    <div key={index} className={`relative`}>
      <div className="bg-gray-300 animate-pulse relative w-full h-[200px] rounded-lg"></div>

      <div className="absolute top-2 right-2 bg-gray-400 animate-pulse w-6 h-6 rounded-full"></div>
      <div className="flex items-center justify-between pt-2">
        <div className="flex items-center gap-1">
          <div className="bg-gray-400 animate-pulse w-6 h-6 rounded-full"></div>
          <div>
            <div className="bg-gray-400 animate-pulse w-20 h-2 rounded mb-1"></div>
            <div className="bg-gray-300 animate-pulse w-12 h-2 rounded"></div>
          </div>
        </div>
        <div className="bg-gray-400 animate-pulse w-7 h-7 rounded-full"></div>
      </div>
    </div>
  );
};

export const CardSkelton_2 = (props) => {
  return (
    <div className={`relative`}>
      <div className="bg-gray-300 animate-pulse relative w-full h-[350px] rounded-lg flex justify-center items-center">
        <Loader
          setVideoResponse={props.setVideoResponse}
          videoResponse={props.videoResponse}
        />
      </div>
    </div>
  );
};

const Loader = ({ videoResponse, setVideoResponse }) => {
  const texts = [
    "Analyzing query",
    "Processing",
    "Matching videos",
    "Performing actions",
    "Finalizing video",
    "Generating video",
  ];
  const circumference = 2 * Math.PI * 20;
  useEffect(() => {
    const timer = setInterval(() => {
      setVideoResponse((prevProgress) => {
        const newProgress =
          prevProgress.progress >= 100 ? 100 : prevProgress.progress + 5;
        let textIndex;

        if (newProgress < 15) {
          textIndex = 0;
        } else if (newProgress < 30) {
          textIndex = 1;
        } else if (newProgress < 55) {
          textIndex = 2;
        } else if (newProgress < 70) {
          textIndex = 3;
        } else if (newProgress < 100) {
          textIndex = 4;
        } else {
          textIndex = 5;
        }

        return {
          ...prevProgress,
          progress: newProgress,
          textIndex,
        };
      });
    }, 30000);
    return () => {
      clearInterval(timer);
    };
  }, [setVideoResponse]);

  return (
    <div className="flex items-center gap-3">
      <svg className="transform -rotate-90 w-[60px] h-[60px]">
        <circle
          cx="25"
          cy="25"
          r="18"
          stroke="currentColor"
          strokeWidth="5"
          fill="transparent"
          className="text-[#F3F3EE]"
        />
        <circle
          cx="25"
          cy="25"
          r="18"
          stroke="currentColor"
          strokeWidth="5"
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={
            circumference - (videoResponse.progress / 100) * circumference
          }
          className="text-secondary"
        />
      </svg>
      <div className="m-0 text-lg font-[500] flex items-end gap-1 ">
        <p>{texts[videoResponse.textIndex]}</p>
        <ThreeDots
          height="20"
          width="20"
          radius="9"
          color="#000"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    </div>
  );
};

export const CardSkelton_3 = ({ index }) => {
  return (
    <div key={index} className={`relative`}>
      <div className="bg-gray-300 animate-pulse relative w-full h-[150px] rounded-lg"></div>
    </div>
  );
};

export const CardSkelton_4 = () => {
  return (
    <div className={`relative`}>
      <div className="bg-gray-300 animate-pulse relative w-full h-[100px] rounded-lg flex justify-center items-center">
        <div className="bg-gray-400 animate-pulse w-6 h-6 rounded-full"></div>
      </div>
    </div>
  );
};
