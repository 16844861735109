import axios from "axios";
import { useContext, useState } from "react";
import GlobalContext from "../context/GlobalContext";

const useGetUserInfo = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL_SSO;
  const { setUserInfo } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);

  const getCurrentUser = async (token) => {
    setLoading(true);
    await axios
      .get(`${BASE_URL}/api/accounts/profile/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        setLoading(false);
        setUserInfo(response.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return { loading, getCurrentUser };
};
export default useGetUserInfo;
